.light .sudoku .square {
    background: #FFFFFF;
    color: #000000;
}

.light .sudoku .square .notes table td {
    color: #00008B;
}

.light .sudoku .square.guess {
    color: #00008B;
}

.light .sudoku .square .mistake,
.light .sudoku .message .mistakes {
    color: #8B0000;
}

.light .sudoku .square .mistake {
    border: 2px dotted #FFAAAA;
}

.light .sudoku .square .empty {
    border: 2px dotted #AAAAFF;
}

.light .sudoku .square.correct {
    color: #003500;
}

.light .sudoku .square.active {
    background: #AFEEEE !important;
}

.light .sudoku .square.highlight {
    background: #B0C4DE;
}

.light .sudoku .grid {
    background-color: #EEEEEE;
}

.light .sudoku .b1,
.light .sudoku .b3,
.light .sudoku .b5,
.light .sudoku .b7 {
    background: #EEEEEE;
}

.light .sudoku .r0 {
    border-top: 2px solid #333333;
}

.light .sudoku .r8 {
    border-bottom: 2px solid #333333;
}

.light .sudoku .c0 {
    border-left: 2px solid #333333;
}

.light .sudoku .c8 {
    border-right: 2px solid #333333;
}

.light .sudoku .r2,
.light .sudoku .r5 {
    border-bottom: 2px solid #333333;
}

.light .sudoku .c2,
.light .sudoku .c5 {
    border-right: 2px solid #333333;
}

.light .sudoku .r0,
.light .sudoku .r1,
.light .sudoku .r3,
.light .sudoku .r4,
.light .sudoku .r6,
.light .sudoku .r7 {
    border-bottom: 1px solid #666666;
}

.light .sudoku .c0,
.light .sudoku .c1,
.light .sudoku .c3,
.light .sudoku .c4,
.light .sudoku .c6,
.light .sudoku .c7 {
    border-right: 1px solid #666666;
}
