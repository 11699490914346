.sudoku .top,
.sudoku .options,
.sudoku .message {
    width: 328px;
    margin: 0 auto;
}

.sudoku .grid {
    width: 314px;
    margin: 0 auto;
    padding: 4px;
    border-radius: 4px;
}

.sudoku .timer {
    margin-bottom: 5px;
}

.sudoku .numbers {
    width: 250px;
    height: 140px;
    margin: 20px auto 0;
}

.sudoku .numbers button {
    display: inline-block;
    margin: 8px;
}

.short .sudoku .numbers {
    width: 340px;
    height: 40px;
    margin: 20px auto 0;
}

.short .sudoku .numbers button {
    display: inline-block;
    margin: 2px 4px;
    min-width: 28px;
}

.sudoku .message {
    margin-top: 10px;
}

.sudoku .options {
    margin-top: 20px;
}

.sudoku .options div {
    text-align: left;
}

.sudoku .options input,
.sudoku .options label {
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.sudoku .actions {
    margin-top: 0;
}

.sudoku .actions .lower {
    margin-top: 10px;
}

.sudoku .square {
    display: inline-block;
    font-size: 24px;
    line-height: 34px;
    height: 34px;
    width: 34px;
    padding: 0;
    text-align: center;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
}

.sudoku .square.guess,
.sudoku .square .mistake,
.sudoku .square .empty,
.sudoku .square.correct {
    font-weight: bolder;
}

.sudoku .square .notes,
.sudoku .square .mistake,
.sudoku .square .empty {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 31px;
    height: 31px;
    width: 32px;
}

.sudoku .square .notes table {
    height: 34px;
    width: 34px;
    margin: 0;
    padding: 0;
    border-spacing: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.sudoku .square .notes table td {
    height: 11px;
    width: 11px;
    font-size: 10px;
    line-height: 10px;
}

.sudoku .play-again svg,
.sudoku .reset svg,
.sudoku .pause svg,
.sudoku .save svg {
    height: 20px;
    margin-right: 5px;
    margin-left: -5px;
}

.sudoku .MuiBackdrop-root {
    z-index: 100;
}

.sudoku.paused .square,
.sudoku.paused .square .notes table td {
    color: transparent !important;
    text-shadow: 0 0 8px #000000;
}

.sudoku .play {
    color: #FFFFFF;
    height: 64px;
    width: 64px;
}
